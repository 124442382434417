
import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import "simple-syntax-highlighter/dist/sshpre.css";
import UserAPI from "@/services/api/user";
import { DatatableFooterProps, DefaultFooterProps } from "@/types/veutify";
import { mdiMagnify } from "@mdi/js";
import UserProfile, { SerializedUserProfile } from "@/models/user-profile";
import { ApiResponse } from "@/services/api/axios";

@Component
export default class AdminReferralsIndex extends Vue {
    loadingUsers: boolean = true;
    users: Array<UserProfile> = [];
    searchIcon: string = mdiMagnify;
    query: string = "";
    footerProps: DatatableFooterProps = DefaultFooterProps;
    headers = [
        {
            text: "Updated At",
            value: "updatedAt",
        },
        {
            text: "Name",
            value: "name",
        },
        {
            text: "Email",
            value: "email",
        },
        {
            text: "Clicks Count",
            value: "clicksCount",
        },
        {
            text: "Phone Number",
            value: "phoneNumber",
        },
        {
            text: "Referral Code",
            value: "referralCode",
        },
        {
            text: "Total Earnings",
            value: "amountEarned",
        },
        { text: "Details", value: "data-table-expand" },
    ];

    get referralsData() {
        return this.users.map((user) => {
            return {
                id: user.userId,
                updatedAt: user.updatedAt,
                name: user.name,
                email: user.email,
                paymentPhoneNumber: user.referral?.phoneNumber,
                orders: user.referral?.orders,
                clicksCount: user.referral?.clicksCount,
                phoneNumber: user.referral?.phoneNumber,
                referralCode: user.referral?.code,
                amountEarned: user.referral?.totalAmount,
            };
        });
    }

    mounted() {
        this.loadReferrals();
        this.query =
            this.$route.query["query"].length > 0
                ? (this.$route.query["query"] as string) ?? ""
                : "";
    }

    loadReferrals() {
        UserAPI.adminIndex()
            .then((response: AxiosResponse) => {
                this.users = response.data.data.map(
                    (event: SerializedUserProfile) => {
                        return new UserProfile(event);
                    }
                );
                this.loadingUsers = false;
            })
            .catch(this.handleAxiosError);
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
